<template>
  <div id="js-loader" class="loader">
    <div class="loader-animation"></div>
  </div>
  <div class="page_header">
    <div class="mv_cloud">
      <div class="right_area">
        <div class="img04">
          <img src="@/assets/front_component/images/assets/back02.png" alt="雲" />
        </div>
      </div>
      <div class="left_area">
        <div class="img01">
          <img src="@/assets/front_component/images/assets/back01.png" alt="雲" />
        </div>
      </div>
    </div>
    <div class="en_ttl en"><span>MY EVENT</span></div>
    <div class="content_wrap">
      <h2>My イベント</h2>
      <div class="mascot_wrap" style="display: none;">
        <img
          src="@/assets/front_component/images/assets/meguru05.png"
          alt="めぐる君"
        />
      </div>
      <div
        class="user_img"
        :style="{ backgroundImage: 'url(' + profileImg + ')' }"
        v-if="profileImg"
      ></div>
      <div
        class="user_img"
        :style="{
          backgroundImage:
            'url(' +
            require('@/assets/front_component/images/test/test01.png') +
            ')',
        }"
        v-else
      ></div>
    </div>
  </div>
  <main>
    <div class="section_all_wrap">
      <div class="content_in_wrap">
        <div id="breadcrumb">
          <ul class="breadcrumb_list">
            <li>
              <router-link :to="{ name: 'Control event' }">My イベント一覧</router-link>
            </li>
            <li><span>編集</span></li>
          </ul>
        </div>
        <Form
          enctype="multipart/form-data"
          autocomplete="off"
          @submit="update(editevent.id)"
          v-slot="{ errors }"
        >
          <div class="content">
            <div class="innar">
              <div class="form_outwrap">
                <div class="wrap wid_100per">
                  <p class="ttl_03">
                    タイトル <ErrorMessage class="each_msg" name="title" />
                  </p>
                  <div class="in_wrap">
                    <Field
                      name="title"
                      type="text"
                      class="form-control"
                      :class="{ 'is-invalid': errors.title }"
                      v-model="editevent.title"
                      :rules="isRequired"
                    />
                  </div>
                </div>
                <div class="wrap wid_100per">
                  <p class="ttl_03">
                    本文<ErrorMessage class="each_msg" name="content" />
                  </p>
                  <div class="in_wrap">
                    <ckeditor
                      :editor="editor"
                      v-model="editevent.content"
                      :config="editorConfig"
                      @input="onEditorInput"
                    ></ckeditor>
                    <Field
                      name="content"
                      type="hidden"
                      class="form-control"
                      placeholder="本文を入力してください"
                      :class="{ 'is-invalid': errors.content }"
                      v-model="editevent.content"
                      :rules="isRequired"
                    />
                  </div>
                </div>
                <div class="wrap wid_100per" style="display: none">
                  <p class="ttl_03">
                    ステータス <ErrorMessage class="each_msg" name="status" />
                  </p>
                  <div class="in_wrap">
                    <div class="select_wrap">
                      <Field
                        required=""
                        name="status"
                        as="select"
                        v-model="editevent.status"
                        :rules="isRequired"
                      >
                        <option value="下書き">下書き</option>
                        <option value="公開">公開</option>
                      </Field>
                    </div>
                  </div>
                </div>
                <div class="wrap wid_100per" style="display: none">
                  <p class="ttl_03">
                    公開日 <ErrorMessage class="each_msg" name="published_at" />
                  </p>
                  <div class="in_wrap">
                    <Field
                      name="published_at"
                      type="datetime-local"
                      class="form-control"
                      v-model="editevent.published_at"
                    />
                  </div>
                </div>
                <div v-if="attached_file" class="wrap wid_100per">
                  <p class="ttl_03">
                    関連資料<span class="" style="font-size: 1.4rem;margin-left: 0.5em;">※アップロードできるファイルはpdfファイルです。</span>
                    <ErrorMessage class="each_msg" name="attached_file" />
                  </p>
                  
                  <div
                    v-if="editevent.attached_file"
                    class="in_wrap"
                    id="attached_area"
                  >
                    <div
                      v-for="file in editevent.attached_file"
                      :key="file.id"
                      class="attached_wrap"
                    >
                      <div class="attached_name_btn">
                        <input
                          name="rel_doc_title[]"
                          type="text"
                          class="fileName form-control"
                          required
                          placeholder="例：今月のスケジュールについて"
                          :value="file.display_name"
                          :data-id="file.id"
                        />
                      </div>
                      <div class="attached_fire_btn">
                        <a
                          v-on:click="dlfile(file.storage_file_name)"
                          class="en"
                          target="_blank"
                          :download="file.updaload_name"
                          ><i class="far fa-file-pdf"></i>{{file.updaload_name}}</a
                        >
                      </div>
                      <div class="attached_action_btn">
                        <div class="add_task_link task_link_btn">
                          <i class="fas fa-plus"></i>
                        </div>
                        <div
                          @click="delete_file(file.id)"
                          class="delete_task_link task_link_btn"
                          tabindex="-1"
                          data-target=""
                        >
                          <i class="fas fa-minus"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else class="in_wrap" id="attached_area">
                    <div class="attached_wrap">
                      <div class="attached_name_btn">
                        <input
                          name="rel_doc_title[]"
                          type="text"
                          class="fileName form-control"
                          required
                          placeholder="例：今月のスケジュールについて"
                        />
                      </div>
                      <div class="attached_fire_btn">
                        <label>
                          <input
                            type="file"
                            name="rel_doc[]"
                            class="input-file form-control"
                            accept=".pdf"
                          />
                          <span class="file_name">添付ファイル</span>
                        </label>
                      </div>
                      <div class="attached_action_btn">
                        <div class="add_task_link task_link_btn">
                          <i class="fas fa-plus"></i>
                        </div>
                        <div
                          class="delete_task_link task_link_btn"
                          tabindex="-1"
                          data-target=""
                        >
                          <i class="fas fa-minus"></i>
                        </div>
                      </div>
                      <div class="fileDisplayName"></div>
                    </div>
                  </div>
                </div>

                <div class="wrap wid_100per">
                  <p class="ttl_03">
                    問い合わせ先
                    <ErrorMessage class="each_msg" name="contact" />
                  </p>
                  <div class="in_wrap">
                    <Field
                      name="contact"
                      type="text"
                      class="form-control"
                      :class="{ 'is-invalid': errors.contact }"
                      v-model="editevent.contact"
                    />
                  </div>
                </div>
                <p class="ttl_03">イベント情報</p>
                <br />
                <div class="wrap wid_100per">
                  <p class="ttl_03">
                    最大参加人数
                    <ErrorMessage class="each_msg" name="capacity" />
                  </p>
                  <div class="in_wrap">
                    <Field
                      name="capacity"
                      type="text"
                      class="form-control"
                      :class="{ 'is-invalid': errors.capacity }"
                      v-model="editevent.capacity"
                      oninput="value = value.replace(/[０-９]/g,s => String.fromCharCode(s.charCodeAt(0) - 65248)).replace(/\D/g,'');"
                    />
                  </div>
                </div>

                <div class="wrap wid_100per">
                  <p class="ttl_03">
                    開催場所 <ErrorMessage class="each_msg" name="place" />
                  </p>
                  <div class="in_wrap">
                    <Field
                      name="place"
                      type="text"
                      class="form-control"
                      :class="{ 'is-invalid': errors.place }"
                      v-model="editevent.place"
                    />
                  </div>
                </div>

                <div class="wrap wid_100per">
                  <p class="ttl_03">
                    開始日時
                    <ErrorMessage class="each_msg" name="start_datetime" />
                  </p>
                  <div class="in_wrap">
                    <Field
                      name="start_datetime"
                      type="datetime-local"
                      class="form-control"
                      v-model="editevent.implementation_datetime.start_datetime"
                      :rules="isRequired"
                    />
                  </div>
                </div>

                <div class="wrap wid_100per">
                  <p class="ttl_03">
                    終了日時
                    <ErrorMessage class="each_msg" name="end_datetime" />
                  </p>
                  <div class="in_wrap">
                    <Field
                      name="end_datetime"
                      type="datetime-local"
                      class="form-control"
                      v-model="editevent.implementation_datetime.end_datetime"
                      :rules="isRequired_endtime"
                    />
                  </div>
                </div>

                <p class="ttl_03">アイキャッチ</p>
                <div class="post_img_wrap eyecatch">
                  <img id="eyecatch_img" src="" style="display: none" />
                </div>
                <div class="t_l m_b40">
                  <div class="camera_btn" id="post_img_area">
                    <label>
                      <Field
                        name="eyecatch"
                        type="file"
                        class="input-file form-control post_img-file"
                        :class="{ 'is-invalid': errors.eyecatch }"
                        v-model="editevent.eyecatch"
                        :rules="isRequiredphoto"
                        accept="image/*"
                      />

                      <!-- <input type="file" accept="image/*" capture="camera"> -->
                      <span>画像を選択</span>
                    </label>
                  </div>
                  <ErrorMessage class="each_msg" name="eyecatch" />
                </div>
              </div>
              <div class="t_c">
                <div class="btn_large">
                  <button :disabled="processing" type="submit" class="btn btn-primary">
                    送信
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </div>
      <div class="kusa_outline">
        <div class="kusa_area">
          <div class="deco_wrap01">
            <img src="@/assets/front_component/images/assets/kusa01.png" alt="草" />
          </div>
          <div class="deco_wrap02">
            <img src="@/assets/front_component/images/assets/kusa02.png" alt="草" />
          </div>
          <div class="deco_wrap03">
            <img src="@/assets/front_component/images/assets/kusa04.png" alt="草" />
          </div>
          <div class="deco_wrap04">
            <img src="@/assets/front_component/images/assets/kusa03.png" alt="草" />
          </div>
          <div class="deco_wrap05">
            <img src="@/assets/front_component/images/assets/kusa04.png" alt="草" />
          </div>
          <div class="deco_wrap06">
            <img src="@/assets/front_component/images/assets/kusa03.png" alt="草" />
          </div>
          <div class="deco_wrap07">
            <img src="@/assets/front_component/images/assets/kusa05.png" alt="草" />
          </div>
          <div class="deco_wrap08">
            <img src="@/assets/front_component/images/assets/kusa04.png" alt="草" />
          </div>
          <div class="deco_wrap09">
            <img src="@/assets/front_component/images/assets/kusa01.png" alt="草" />
          </div>
          <div class="deco_wrap10">
            <img src="@/assets/front_component/images/assets/kusa07.png" alt="草" />
          </div>
          <div class="deco_wrap11">
            <img src="@/assets/front_component/images/assets/kusa06.png" alt="草" />
          </div>
          <div class="deco_wrap12">
            <img src="@/assets/front_component/images/assets/kusa01.png" alt="草" />
          </div>
          <div class="deco_wrap13">
            <img src="@/assets/front_component/images/assets/kusa04.png" alt="草" />
          </div>
          <div class="deco_wrap14">
            <img src="@/assets/front_component/images/assets/kusa03.png" alt="草" />
          </div>
          <div class="deco_wrap15">
            <img src="@/assets/front_component/images/assets/kusa04.png" alt="草" />
          </div>
          <div class="deco_wrap16">
            <img src="@/assets/front_component/images/assets/kusa03.png" alt="草" />
          </div>
          <div class="deco_wrap17">
            <img src="@/assets/front_component/images/assets/kusa01.png" alt="草" />
          </div>
        </div>
      </div>
      <div class="deco_outline">
        <div class="deco_area">
          <div class="deco_wrap01">
            <img src="@/assets/front_component/images/assets/kikyu01.png" alt="気球" />
          </div>
          <div class="deco_wrap02">
            <img
              src="@/assets/front_component/images/assets/hana01.png"
              alt="花"
              class="hana01"
            />
            <img
              src="@/assets/front_component/images/assets/hana02.png"
              alt="花"
              class="hana02"
            />
            <img
              src="@/assets/front_component/images/assets/hana03.png"
              alt="花"
              class="hana03"
            />
          </div>
          <div class="deco_wrap03">
            <img
              src="@/assets/front_component/images/assets/hana01.png"
              alt="花"
              class="hana01"
            />
            <img
              src="@/assets/front_component/images/assets/hana02.png"
              alt="花"
              class="hana02"
            />
            <img
              src="@/assets/front_component/images/assets/hana03.png"
              alt="花"
              class="hana03"
            />
          </div>
          <div class="deco_wrap04">
            <img
              src="@/assets/front_component/images/assets/heri01.png"
              alt="ヘリコプター"
            />
          </div>
          <div class="deco_wrap05">
            <img src="@/assets/front_component/images/assets/kikyu02.png" alt="気球" />
          </div>
          <div class="deco_wrap06">
            <img
              src="@/assets/front_component/images/assets/hana01.png"
              alt="花"
              class="hana01"
            />
            <img
              src="@/assets/front_component/images/assets/hana02.png"
              alt="花"
              class="hana02"
            />
            <img
              src="@/assets/front_component/images/assets/hana03.png"
              alt="花"
              class="hana03"
            />
          </div>
          <div class="deco_wrap07">
            <img
              src="@/assets/front_component/images/assets/hana01.png"
              alt="花"
              class="hana01"
            />
            <img
              src="@/assets/front_component/images/assets/hana02.png"
              alt="花"
              class="hana02"
            />
            <img
              src="@/assets/front_component/images/assets/hana03.png"
              alt="花"
              class="hana03"
            />
          </div>
          <div class="deco_wrap08">
            <img src="@/assets/front_component/images/assets/kikyu03.png" alt="気球" />
          </div>
        </div>
      </div>
    </div>
  </main>
  <div class="menu_wrap">
    <div class="menu_icon">
      <img src="@/assets/front_component/images/assets/menu01.png" alt="メニュー" />
    </div>
    <div class="menu_list_wrap">
      <div class="menu_ttl">作成する</div>
      <ul class="menu_list">
          <!-- <li><router-link :to="{ name: 'Control messagecreate', params: { type: 'news' }}">お知らせ</router-link></li> -->
          <li><router-link :to="{ name: 'Control messagecreate', params: { type: 'thanks' }}">サンクス&ホメロンカード</router-link></li>
          <li><router-link :to="{ name: 'Control messagecreate', params: { type: 'idea' }}">アイディアカード</router-link></li>
          <li><router-link :to="{ name: 'Control messagecreate', params: { type: 'daily' }}">気づき日報</router-link></li>
          <li><router-link :to="{ name: 'Control messagecreate', params: { type: 'photolog' }}">フォトログ</router-link></li>
          <li><router-link :to="{ name: 'Control messagecreate', params: { type: 'column' }}">コラム</router-link></li>
          
      </ul>
    </div>
  </div>
  <div id="Template_attached_file">
    <div class="attached_wrap new_item">
      <div class="attached_name_btn">
        <input
          name="rel_doc_title[]"
          type="text"
          class="fileName form-control"
          required
          placeholder="例：今月のスケジュールについて"
        />
      </div>
      <div class="attached_fire_btn">
        <label>
          <input
            type="file"
            name="rel_doc[]"
            class="input-file form-control"
            accept=".pdf"
          />
          <span class="file_name">添付ファイル</span>
        </label>
      </div>
      <div class="attached_action_btn">
        <div class="add_task_link task_link_btn">
          <i class="fas fa-plus"></i>
        </div>
        <div class="delete_task_link task_link_btn" tabindex="-1">
          <i class="fas fa-minus"></i>
        </div>
      </div>
      <div class="fileDisplayName"></div>
    </div>
  </div>
</template>

<script>
import Api from "@/apis/Api";
import "@/assets/front_component/js/functions.js";
import "@/assets/front_component/js/select2/select2.min.js";
import $ from "jquery";
import message from "@/apis/Message";
import event from "@/apis/Event";
import ClassicEditor from "ckeditor5-build-classic-with-base64-and-autolink/build/ckeditor";
import { Form, Field, ErrorMessage } from "vee-validate";
import dayjs from "dayjs";
import moment from "moment";

export default {
  name: "event",
  data() {
    return {
      editevent: {
        implementation_datetime: {
          start_datetime: null,
          end_datetime: null,
        },
        reception_period: {
          start_datetime: null,
          end_datetime: null,
        },
        eyecatch: null,
        eyecatch_get: null,
      },
      editor: ClassicEditor,
      editorConfig: {},
      editorValidation: true,
      old_fileid: "",
      before_thanks_type: [],
      processing: false,
      attached_file: [],
      delete_attached_file: [],
      profileImg: false,
    };
  },
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  beforeCreate() {
    $(function () {
      // body に open クラスをつけたりはずしたりする( open クラスは空)
      $(".g_header_list").removeClass("open");
      $("#button").removeClass("active");
      $(document.body).removeClass("open");
      var result = $("body").attr("style");
      var int_data = result.replace(/[^0-9]/g, "");
      $("html,body").css({
        overflow: "auto",
        height: "auto",
      });
      //bodyfixedを解除する
      $("body").css({
        position: "",
        width: "",
        top: "",
      });
      $(window).scrollTop(int_data);
      $(".g_header_list  > li.has_child").removeClass("open");
      $(".g_header_list  > li.has_child").find(".menu_outwrap").slideUp();
    });
    $(function () {
      $("#js-loader").show();
      $(window).scrollTop(0);
    });
  },
  created() {
    this.event_id = this.$route.params.id;
    this.editmessageView(this.event_id);
    this.getMessageEyecatch(this.event_id);
    this.profileImgApi();
  },
  mounted() {
    let vueThis = this;
    document.body.className = "page_control";
    this.$nextTick(function () {
      $(document).ready(function () {
        var not = 0;
        $(document).on("change", ".post_img-file", function (e) {
          if (!this.files.length) {
            return;
          }

          var $files = $(this).prop("files");

          var file = $files[0];
          var fr = new FileReader();

          fr.onload = function (e) {
            var src = e.target.result;
            let img = document.getElementById("eyecatch_img");
            img.src = src;
            $("#eyecatch_img").css("display", "block");
          };

          fr.readAsDataURL(file);

          $("#preview").css("display", "block");
        });
      });
      $(function () {
        $(".menu_icon").click(function () {
          if ($(".menu_list_wrap").hasClass("show")) {
            $(".menu_list_wrap").removeClass("show");
          } else {
            $(".menu_list_wrap").addClass("show");
          }
        });
      });
      $(document).click(function (event) {
        if (!$(event.target).closest(".menu_wrap").length) {
          $(".menu_list_wrap").removeClass("show");
        }
      });
      $(function () {
        var content_height = $("#app").height(); // コンテンツの高さを取得
        var pagetop_show = content_height - 1500; // ページトップを出すの高さを設定
        $(window).on("scroll", function () {
          var scroll = $(window).scrollTop();
          var windowHeight = $(window).height();
          //ページトップ表示
          if (scroll > pagetop_show && scroll > 60) {
            $(".pagetop_wrap").addClass("show");
          } else {
            $(".pagetop_wrap").removeClass("show");
          }
        });
      });
      $(function () {
        $("#js-loader").delay(300).fadeOut(600);
        $(window).scrollTop(0);
      });
    });
    
    $(function () {
      $(".target_user_select").select2({
        width: "260px",
        language: {
          noResults: function () {
            return "登録されていません";
          },
        },
        placeholder: "宛先のユーザーを選択",
        allowClear: true,
        multiple: true,
      });
    });
    $(document).on("change", ".input-file", function (e) {
      var $el = $(e.currentTarget);
      var $fileName = $el.closest(".attached_wrap,.new_item").find(".fileName");
      var $filesSelectName = $el.closest(".attached_wrap,.new_item").find(".file_name");
      var $fileDisplayName = $el
        .closest(".attached_wrap,.new_item")
        .find(".fileDisplayName");

      $($filesSelectName).text(e.target.files[0].name);

      if ($fileName.val() == "") {
        if (e.target.files[0].name) {
          $fileName.val(e.target.files[0].name);
        } else {
          var today = new Date();
          $fileName.val(today + e.target.files[0].type);
          // $($fileDisplayName).html("資料名を入力してください");
          // $($fileDisplayName).addClass('each_msg')
        }
        return false;
      } else {
        $($fileDisplayName).html("");
        $($fileDisplayName).removeClass("each_msg");
      }
    });

    $(document).on("change", ".fileName", function (e) {
      var $el = $(e.currentTarget);
      var $fileData = $el.closest(".attached_wrap,.new_item").find(".input-file");
      var $fileDisplayName = $el
        .closest(".attached_wrap,.new_item")
        .find(".fileDisplayName");
      if ($el.val() == "") {
        $($fileDisplayName).html("資料名を入力してください");
        $($fileDisplayName).addClass("each_msg");
        return false;
      } else {
        $($fileDisplayName).html("");
        $($fileDisplayName).removeClass("each_msg");
      }
    });
    $(function () {
      $("div.delete_task_link").each(function (i) {
        $(this).attr("data-target", i + 1);
      });
    });
    $(document).on("click", ".add_task_link ", function () {
      $("div.delete_task_link").each(function (i) {
        $(this).attr("data-target", i + 1);
      });
    });

    $(document).on("click", ".add_task_link", function (e) {
      var $el = $(e.currentTarget);
      var $itembox = $el.closest(".attached_wrap,.new_item");
      var htmlString = $("#Template_attached_file").html();
      $itembox.after(htmlString);
    });

    $(document).on("click", ".delete_task_link", function (e) {
      // 最初のマイナスボタンはクリアの動き
      var $el = $(e.currentTarget).not('[data-target="1"]');
      var $row = $el.closest(".attached_wrap,.new_item");
      $row.remove();

      var $fileName = $el.closest(".attached_wrap,.new_item").find(".fileName");
      console.log(vueThis.attached_file);

      for (var i = 0; i < vueThis.attached_file.length; i++) {
        console.log(vueThis.attached_file[i]);
        if (vueThis.attached_file[i].display_name == $fileName.val()) {
          vueThis.attached_file.splice(i, 1);
          break;
        }
      }
      return false;
    });
    $(document).on("blur", ".fileName", function (e) {
      console.log(e);
      var $el = $(e.currentTarget);
      console.log($el);
      $el[0].dataset.value = $el.val();
    });
  },
  methods: {
    onEditorInput( data, event, editor ) {

    editor.editing.view.document.on( 'enter', ( evt, data ) => {

         if ( data.isSoft ) {
             editor.execute( 'enter' );
         } else {
             editor.execute( 'shiftEnter' );
    }

         data.preventDefault();
         evt.stop();
         editor.editing.view.scrollToTheSelection();

     }, { priority: 'high' } );

 },
    profileImgApi() {
      Api()
        .get("api/v1/files/users/" + this.$User.id + "?where=key@users")
        .then(
          (response) => {
            if (response != "") {
              let path = response.data.data[0].storage_file_name;
              let fileApiPass = "/api/v1/file/" + path;
              Api()
                .get(fileApiPass, { responseType: "arraybuffer" })
                .then(
                  (response) => {
                    let blob = new Blob([response.data], {
                      type: response.headers["content-type"],
                    });
                    let url = window.URL || window.webkitURL;
                    let src = url.createObjectURL(blob);
                    this.profileImg = src;
                    //console.log(this.profileImg);
                  },
                  (error) => {}
                );
            }
          },
          (error) => {
            console.log(error);
          }
        )
        .catch(() => {});
    },
    getMessageEyecatch(message_id) {
      event
        .showfile(message_id, "key", "eyecatch")
        .then((response) => {
          if (response != null) {
            this.editevent.eyecatch_get = response.data.data[0];
            let fileApiPass =
              "/api/v1/file/" + this.editevent.eyecatch_get.storage_file_name;
            let img = document.getElementById("eyecatch_img");
            img.src = "data:image/jpeg;base64," + this.editevent.eyecatch_get.base64;
            $("#eyecatch_img").css("display", "block");
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {});
    },
    delete_file(file_id) {
      this.delete_attached_file.push(file_id);
    },

    isRequired(value) {
      if (value) {
        return true;
      }
      return "必須です";
    },
    isRequiredArr(value) {
      if (value.length != 0) {
        return true;
      }
      return "必須です";
    },
    isRequiredphoto(value) {
      console.log(value);
      var error_message = "";

      if (!value && value == undefined) {
        return true;
      } else {
        console.log(value);
        console.log(value[0].type);
        // ファイルサイズチェック
        if (value[0].size > 3000000) {
          error_message = "ファイルサイズは3MB以下にしてください";
        }
        if (value[0].type != "image/jpeg" && value[0].type != "image/png") {
          error_message += "アップロードできるファイルの形式はjpg,pngです";
        }
      }

      if (error_message == "") {
        return true;
      }
      return error_message;
    },
    isRequired_endtime(value) {
      if (value) {
        if (
          this.editevent.implementation_datetime.start_datetime &&
          this.editevent.implementation_datetime.start_datetime >= value
        ) {
          return "終了日は開始日以降に設定してください";
        } else {
          return true;
        }
      } else {
        if (this.editevent.implementation_datetime.start_datetime != null) {
          var day = this.editevent.implementation_datetime.start_datetime.substring(
            0,
            this.editevent.implementation_datetime.start_datetime.indexOf("T")
          );
          this.editevent.implementation_datetime.end_datetime = day + "T23:59";
          return true;
        } else {
          return true;
        }
      }
    },
    enable: function () {
      this.processing = false;
    },
    editmessageView(id) {
      this.loader = true;
      event
        .edit(id)
        .then((response) => {
          if (response != null) {
            this.editevent = response.data.data;
            console.log(this.editevent);

            this.getEventAttachedFile(id);
            if (this.editevent.published_at) {
              this.editevent.published_at = this.format_date(this.editevent.published_at);
              this.editevent.published_at = this.editevent.published_at.replace(" ", "T");
              this.editevent.published_at = moment(new Date()).format(
                "YYYY-MM-DD HH:mm:ss"
              );
            }

            this.editevent.implementation_datetime.start_datetime = this.format_date(
              this.editevent.implementation_datetime.start_datetime
            );
            this.editevent.implementation_datetime.start_datetime = this.editevent.implementation_datetime.start_datetime.replace(
              " ",
              "T"
            );

            this.editevent.implementation_datetime.end_datetime = this.format_date(
              this.editevent.implementation_datetime.end_datetime
            );
            this.editevent.implementation_datetime.end_datetime = this.editevent.implementation_datetime.end_datetime.replace(
              " ",
              "T"
            );

            this.editevent.reception_period.start_datetime = this.format_date(
              this.editevent.reception_period.start_datetime
            );
            this.editevent.reception_period.start_datetime = this.editevent.reception_period.start_datetime.replace(
              " ",
              "T"
            );

            this.editevent.reception_period.end_datetime = this.format_date(
              this.editevent.reception_period.end_datetime
            );
            this.editevent.reception_period.end_datetime = this.editevent.reception_period.end_datetime.replace(
              " ",
              "T"
            );
            console.log(this.editevent);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loader = false;
        });
    },
    update(id) {
      this.processing = true;
      setTimeout(this.enable, 3000);

      this.loader = true;
      if (this.editevent.published_at) {
        this.editevent.published_at = this.editevent.published_at.replace("T", " ");
        this.editevent.published_at += ":00";
        this.editevent.published_at = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
      }
      if (this.editevent.implementation_datetime) {
        this.editevent.implementation_datetime.start_datetime = this.editevent.implementation_datetime.start_datetime.replace(
          "T",
          " "
        );
        this.editevent.implementation_datetime.start_datetime += ":00";
        this.editevent.reception_period.start_datetime = this.editevent.implementation_datetime.start_datetime;

        this.editevent.implementation_datetime.end_datetime = this.editevent.implementation_datetime.end_datetime.replace(
          "T",
          " "
        );
        this.editevent.implementation_datetime.end_datetime += ":00";
        this.editevent.reception_period.end_datetime = this.editevent.implementation_datetime.end_datetime;
      }
      for (var i = 0; i < $("#attached_area .attached_wrap").length; i++) {
        let $el = $(".attached_wrap:eq(" + i + ")");
        let $fileName = $el.find(".fileName");

        let $fileData = $el.find(".input-file");
        if ($fileData[0] != undefined) {
          console.log($("#attached_area .attached_wrap").length);
          console.log($fileData[0]);
          if (!($fileName.val() == "" && $fileData[0].files.length == 0)) {
            if ($fileName.val() == "") {
              alert("資料名を入力してください");
              this.attached_file = [];
              return;
            }
            if ($fileData[0].files.length == 0) {
              alert("ファイルを選択してください");
              this.attached_file = [];
              return;
            }
            if (file && permit_type.indexOf(file.type) == -1) {
              alert("アップロードできるファイルの形式はpdfです");
              this.attached_file = [];
              return;
            }

            let fileObject = new FormData();

            fileObject.append("key", "event_attached_file");
            fileObject.append("display_name", $fileName.val());
            fileObject.append("uploadfile", $fileData[0].files[0]);

            this.attached_file.push(fileObject);

            var file = $fileData[0].files[0];
            var permit_type = ["application/pdf"];
          }
        } else {
          console.log($fileName);
          let $fileId = $el.find(".fileName").attr("data-id");
          let $fileName_change = $el.find(".fileName").attr("data-value");
          let put_file = "";
          if ($fileName_change != null) {
            put_file = {
              key: "event_attached_file",
              display_name: $fileName_change,
            };
          } else {
            put_file = {
              key: "event_attached_file",
              display_name: $fileName.val(),
            };
          }
          console.log(put_file);
          this.putMessageAttachedfile(put_file, id, $fileId);
        }
      }
      this.editevent.published_at = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
       $("#js-loader").fadeIn();
      event
        .update(this.editevent, id)
        .then(
          (response) => {
            console.log(response);
            if (this.attached_file.length > 0) {
              this.postMessageAttachedfile(response.data.data.id);
            }
            if (this.delete_attached_file.length > 0) {
              this.delete_attached_file.forEach((file_id) => {
                this.deleteAttachedfile(id, file_id);
              });
            }
            if (this.editevent.eyecatch) {
              if (this.editevent.eyecatch_get) {
                this.deleteAttachedfile(id, this.editevent.eyecatch_get.id);
              }
              this.postMessagefile("eyecatch", "event_eyecatch", response.data.data.id);
            }
              $("#js-loader").fadeOut();
            this.$router.push({
              name: "Control eventshow",
              params: { id: this.event_id },
            });
          },
          (error) => {
            console.log(error);
            console.log(error.response.data.errors);
            this.loader = false;
          }
        )
        .catch(() => {})
        .finally(() => {});
    },
    format_date: (dateStr) => dayjs(dateStr).format("YYYY-MM-DD HH:mm"),

    getEventAttachedFile(event_id) {
      event
        .showfile(event_id, "key", "event_attached_file")
        .then((response) => {
          if (response.data.data.length != 0) {
            this.editevent.attached_file = response.data.data;
            console.log(this.editevent.attached_file);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {});
    },
    dlfile(path) {
      this.windowReference = window.open();
      let fileApiPass = "/api/v1/file/" + path;
      console.log(fileApiPass);
      Api()
        .get(fileApiPass, { responseType: "arraybuffer" })
        .then((response) => {
          let blob = new Blob([response.data], {
            type: response.headers["content-type"],
          });
          /**
           * 新規タブ
           */
          this.windowReference.location = window.URL.createObjectURL(blob);
        });
    },
    async postMessageAttachedfile(createdId) {
      for (var i = 0; i < this.attached_file.length; i++) {
        await event
          .register_file(this.attached_file[i], createdId)
          .then(
            (response) => {
              if (i == this.attached_file.length - 1) {
                if (response != "") {
                  console.log(response);
                }
              }
            },
            (error) => {
              console.log(error);
            }
          )
          .catch(() => {})
          .finally(() => {});
      }
    },
    putMessageAttachedfile(put_file, id, $fileId) {
      event
        .update_file(put_file, id, $fileId)
        .then(
          (response) => {
            console.log("meta");
            console.log(response);
            // this.$router.push({ name: 'Control message', params: { type: (this.message_type) }});
          },
          (error) => {
            console.log("metaerror");
            console.log(error);
            console.log(error.response.data.errors);
            // this.$router.push({ name: 'Front Error' });
          }
        )
        .catch((error) => {
          console.log("metaerrorcatch");
          console.log(error);
          // this.$router.push({ name: 'Front Error Catch' });
        })
        .finally(() => {});
    },

    deleteAttachedfile(id, file_id) {
      event
        .file_delete(id, file_id)
        .then(
          (response) => {
            console.log("file");
            console.log(response);
          },
          (error) => {
            console.log("fileerror");
            console.log(error);
            console.log(error.response.data.errors);
            this.$router.push({ name: "Front Error" });
          }
        )
        .catch((error) => {
          console.log("fileerrorcatch");
          console.log(error);
          this.$router.push({ name: "Front Error Catch" });
        })
        .finally(() => {});
    },

    postMessagefile(key, display_name, id) {
      let fileObject = new FormData();

      fileObject.append("key", key);
      fileObject.append("display_name", display_name);
      fileObject.append("uploadfile", this.editevent.eyecatch[0]);

      console.log(fileObject);
      event
        .register_file(fileObject, id)
        .then(
          (response) => {
            console.log("file");
            console.log(response);
          },
          (error) => {
            console.log("fileerror");
            console.log(error);
            console.log(error.response.data.errors);
            this.$router.push({ name: "Front Error" });
          }
        )
        .catch((error) => {
          console.log("fileerrorcatch");
          console.log(error);
          this.$router.push({ name: "Front Error Catch" });
        })
        .finally(() => {});
    },
  },
};
</script>

<style scoped>
/* 外部CSSスタイルシートを追加する */
</style>
